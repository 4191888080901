import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { canActivate, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { map } from "rxjs/operators";
import { pipe } from "rxjs";
import { HomepageComponent } from "./core/components/homepage/homepage.component";

const redirectUnauthorizedToMainPage = () => redirectUnauthorizedTo(["/"]);

export const redirectNonAdminToHome = () => pipe(
    customClaims,
    map((claim: any) => {
        if (claim?.role === "admin") {
            return true;
        } else {
            return ["/"];
        }
    })
);

export const redirectNonBizwhizToHome = () => pipe(
    customClaims,
    map((claim: any) => {
        if (claim?.role === "admin" || claim?.role === "bizwhiz") {
            return true;
        } else {
            return ["/"];
        }
    })
);

export const redirectNonAngelToHome = () => pipe(
    customClaims,
    map((claim: any) => {
        if (claim?.role === "angel" || claim?.role === "admin" || claim?.role === "bizwhiz") {
            return true;
        } else {
            return ["/"];
        }
    })
);

export const redirectNonPartnerToHome = () => pipe(
    customClaims,
    map((claim: any) => {
        if (claim?.role === "partner" || claim?.role === "admin") {
            return true;
        } else {
            return ["/"];
        }
    })
);

export const redirectNonPatronToHome = () => pipe(
    customClaims,
    map((claim: any) => {
        if (claim?.role === "patron" || claim?.role === "admin" || claim?.role === "bizwhiz") {
            return true;
        } else {
            return ["/"];
        }
    })
);

const routes: Routes = [
    {
        path: "",
        component: HomepageComponent,
        pathMatch: "full"
    }, {
    path: "dashboard",
    children: [{
        path: "",
        loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule),
        ...canActivate(redirectNonPatronToHome)
    }]
},{
    path: "users",
    children: [{
        path: "",
        loadChildren: () => import("./modules/users/users.module").then(m => m.UsersModule),
        ...canActivate(redirectNonAngelToHome)
    }]
},{
    path: "admin",
    children: [{
        path: "",
        loadChildren: () => import("./modules/admin/admin.module").then(m => m.AdminModule),
        ...canActivate(redirectNonBizwhizToHome)
    }]
},{
    path: "errors",
    children: [{
        path: "",
        loadChildren: () => import("./modules/error-tracker/error-tracker.module").then(m => m.ErrorTrackerModule),
        ...canActivate(redirectNonAdminToHome)
    }]
},{
    path: "partner",
    children: [{
        path: "",
        loadChildren: () => import("./modules/partner/partner.module").then(m => m.PartnerModule),
        ...canActivate(redirectNonPartnerToHome)
    }]
},{
    path: "checkout",
    children: [{
        path: "",
        loadChildren: () => import("./modules/payment-checkout/payment-checkout.module").then(m => m.PaymentCheckoutModule),
    }]
},{
    path: "**",
    redirectTo: "/"
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
