<div class="sidebar" [ngClass]="{ expanded: isExpanded }">
    <div class="header">
        <h1 class="title" style="height: 50px">
            <img
                src="../../../assets/images/logo/firsty-logo.svg"
                width="30"
                height="30"
                style="margin-top: 10px"
                class="d-inline-block align-top"
                alt="Firsty"
            />
        </h1>
        <div class="toggle" (click)="handleSidebarToggle()">
            <span class="chevron"></span>
        </div>
    </div>
    <div class="content">
        <div class="link">
            <div
                class="content"
                [routerLink]="['/home']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-house-door-fill"></i>
                </div>
                <div class="title">Home</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'bizwhiz' || userRole === 'patron'">
            <div
                class="content"
                [routerLink]="['/dashboard']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-graph-up"></i>
                </div>
                <div class="title">Dashboard</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'angel' || userRole === 'bizwhiz'">
            <div
                class="content"
                [routerLink]="['/users']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-people-fill"></i>
                </div>
                <div class="title">Users</div>
            </div>
        </div>
        <div class="link with-children" *ngIf="userRole === 'admin' || userRole === 'bizwhiz'">
            <div
                class="content"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-gear-fill"></i>
                </div>
                <div class="title">Management</div>
            </div>
            <div class="children">
                <div class="link">
                    <div
                        class="content"
                        [routerLink]="['/admin/partners']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-briefcase-fill"></i>
                        </div>
                        <div class="title">Partners</div>
                    </div>
                </div>
                <div class="link">
                    <div
                        class="content"
                        [routerLink]="['/admin/promo-codes']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-ticket-perforated-fill"></i> 
                        </div>
                        <div class="title">Promo Codes</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin'">
                    <div
                        class="content"
                        [routerLink]="['/admin/esim-management']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-sim-fill"></i>
                        </div>
                        <div class="title">eSIMs</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin'">
            <div
                class="content"
                [routerLink]="['/errors/tracker']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-bug-fill"></i>
                </div>
                <div class="title">Error Tracker</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
            <div
                class="content"
                [routerLink]="['/partner/api']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-luggage-fill"></i>
                </div>
                <div class="title">Partners API</div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>
