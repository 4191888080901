import { NgModule } from "@angular/core";
import { SortableDirective } from "./directives/sortable.directive";
import { CopyToClipboardDirective } from "./directives/copy-to-clipboard.directive";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
    declarations: [
        SortableDirective,
        CopyToClipboardDirective,
        ProgressBarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgSelectModule
    ],
    exports: [
        SortableDirective,
        CopyToClipboardDirective,
        CommonModule,
        FormsModule,
        NgbModule,
        ProgressBarComponent,
        NgSelectModule
    ]
})
export class SharedModule { }
