import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { canActivate, customClaims } from "@angular/fire/auth-guard"
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { DashboardComponent } from "./dashboard.component";

const routes: Routes = [{
    path: "",
    component: DashboardComponent,
    pathMatch: "full"
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
