<ngb-toast
    *ngFor="let toast of toasts"
    [class.ngb-toasts]="true"
    [class.text-light]="toast.type !== ''"
    [class.bg-success]="toast.type === 'success'"
    [class.bg-danger]="toast.type === 'danger'"
    [class.bg-warning]="toast.type === 'warning'"
    [autohide]="true"
    [delay]="toast.delay"
    [header]="toast.header"
    (hidden)="removeToasts(toast)"
    aria-live="polite"
    aria-atomic="true"
>
    {{ toast.message }}
</ngb-toast>
<app-sidebar *ngIf="isLoggedIn" [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidebar>
<div class="top-bar"></div>
<nav class="navbar navbar-dark backgrounded">
    <a class="navbar-brand" href="/">
        <img
            src="../assets/images/logo/firsty-logo.svg"
            width="30"
            height="30"
            class="d-inline-block align-top"
            alt="firsty"
            style="margin-left: 20px"
        />
        <!-- Backoffice
        <span class="text-light sub-header" *ngIf="isTest">(Test)</span> -->
    </a>
    <ul ngbNav [(activeId)]="active" class="nav-pills">
    </ul>
    <ul ngbNav class="nav-pills">
        <li>
            <!-- <span
                data-bs-toggle="tooltip"
                data-bs-trigger="hover focus"
                data-bs-custom-class="button-tooltip"
                data-bs-placement="top"
                data-bs-title="Claim your admin rights"
            >
                <button
                    type="button"
                    class="btn btn-outline-secondary text-light"
                    *ngIf="!isAdmin && isLoggedIn"
                    (click)="claimAdmin()"
                >
                    <i class="bi bi-person-badge-fill"></i>
                </button>
                &nbsp;&nbsp;
            </span> -->
            <span
                *ngIf="isAdmin && isLoggedIn"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover focus"
                data-bs-custom-class="button-tooltip"
                data-bs-placement="bottom"
                data-bs-title="Copy your Access Token for testing"
            >
                <button
                    copyToClipboard
                    [textToCopy]="token"
                    type="button"
                    class="btn btn-outline-secondary text-light"
                >
                    <i class="bi bi-clipboard2-plus-fill"></i>
                </button>
                &nbsp;&nbsp;
            </span>
            <button
                *ngIf="!isLoggedIn"
                type="button"
                class="btn btn-outline-secondary text-light"
                (click)="loginWithGoogle()"
            >
                Login with Google
            </button>
            <button
                *ngIf="isLoggedIn"
                type="button"
                class="btn btn-outline-secondary text-light"
                (click)="logout()"
            >
                Logout
            </button>
        </li>
    </ul>
</nav>
<div class="progress progress-header">
    <div
        [ngClass]="{ 'progress-bar-striped': isLoading() }"
        class="progress-bar progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="10"
        style="width: 100%"
    ></div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <ngb-alert
                id="altStatus"
                type="info"
                *ngIf="!!status"
                [dismissible]="false"
            >
                <div>{{ status }}</div>
            </ngb-alert>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
